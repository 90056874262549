import { useEffect } from "react"
import qs from "query-string"
import { useToast, UseToastOptions } from "@chakra-ui/react"

export interface QueryToastOptions extends UseToastOptions {
  title: string
  description: string
}

export function withToastQuery(url: string, options: QueryToastOptions) {
  return qs.stringifyUrl({
    url,
    query: {
      "toast-options-base64": window.btoa(JSON.stringify(options)),
    },
  })
}

export function fromToastQuery(url: string): QueryToastOptions | null {
  const { query } = qs.parseUrl(url)
  if (!query) return null

  const optionsEncoded = query["toast-options-base64"]
  if (!optionsEncoded) return null

  if (typeof optionsEncoded !== "string") {
    throw new Error(
      "Expected query string encoded toast options to be a string"
    )
  }

  const options = JSON.parse(window.atob(optionsEncoded)) as QueryToastOptions
  return {
    ...options,
    isClosable: true,
  }
}

export function useQueryStringToast() {
  const toast = useToast()

  useEffect(() => {
    const toastOptions = fromToastQuery(window.location.href)
    toastOptions && toast(toastOptions)
    // Expecting an error here but we just want to run on mount and ignore any changes to `toast`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
